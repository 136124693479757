import FingerprintJS from "@fingerprintjs/fingerprintjs";

export class Fingerprint {
  static async getFingerprint() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    return result.visitorId;
  }
}
