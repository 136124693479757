import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import { nextTick, Plugin } from "vue";
import { CustomObservable, Lang, Utils } from "@cloudpayments/vue-utils";

export type LangArgs = { [param: string]: string };

let isLoaded = false;

const language$ = new CustomObservable<Lang>();

let collection: any;

let currentLanguage: Lang;

export const messagesPromise: Promise<Lang> = new Promise<Lang>((resolve) => {
  const unsub = language$.subscribe((lang) => {
    unsub();
    resolve(getLocaleOrDefault(lang ?? Lang.En));
  });
}).then((language) => {
  currentLanguage = language;
  return import(
    /* webpackChunkName: "language-[request]" */
    `./language/${language}.json`
  );
});

messagesPromise.then((collectionMessages) => {
  collection = collectionMessages;
});

export function getLocaleOrDefault(defaultLocale: Lang): Lang {
  const locale = Utils.getQueryParameterByName("locale") ?? defaultLocale;
  if (locale.toLowerCase() == "kk") {
    return Lang.Ru;
  }
  const language = Object.values(Lang).find((x) =>
    x.toLowerCase().startsWith(locale.substring(0, 2).toLowerCase())
  );

  if (Utils.getQueryParameterByName("locale") && !language) {
    console.warn(
      `Unknown language '${locale}' - falling back to default(${defaultLocale})`
    );
  }
  return language ?? Lang.Ru;
}

export function translate(param: string, args: LangArgs = {}): string {
  let str = getByPath(param);
  if (args?.count) {
    str = pluralizeStr(str, +args?.count);
  }
  for (const arg in args) {
    if (Object.prototype.hasOwnProperty.call(args, arg) && arg) {
      str = str.replace(`{ ${arg} }`, args[arg]);
    }
  }
  return str;
}

export function getByPath(path: string): string {
  const parts = path.split(".");
  let current = collection;
  for (let i = 0; i < parts.length; i++) {
    current = current[parts[i]];
    if (!current) {
      break;
    }
  }
  if (current == null) {
    console.warn(`Unknown language path: '${path}'`);
  }

  return current;
}

export async function loadLocaleMessages(): Promise<void> {
  if (!isLoaded) {
    // Logger.LogInfo(`Loading locales for`, getLocaleOrDefault());
    // load locale messages with dynamic import
    collection = await messagesPromise;
    isLoaded = true;
  }

  return nextTick();
}

export const langPlugin: Plugin = {
  install: (app: any, configuration: DomainConfiguration) => {
    language$.next(configuration.defaultLanguage);
    app.config.globalProperties.$t = (key: string, args?: LangArgs) => {
      return translate(key, args);
    };
  },
};

export function pluralizeStr(str: string, count: number): string {
  const strParts = str.split(" | ");
  if (strParts?.length) {
    switch (currentLanguage) {
      case Lang.Ru: {
        const isTeenNumbers = count > 10 && count < 20;
        const manyStr = strParts[2] || strParts[1] || strParts[0];
        if (!isTeenNumbers) {
          return count % 10 === 1
            ? strParts[0]
            : [2, 3, 4].includes(count % 10)
            ? strParts[1] || strParts[0]
            : manyStr;
        } else {
          return manyStr;
        }
      }
      case Lang.En: {
        return count === 1 ? strParts[0] : strParts[1] || strParts[0];
      }
    }
    return strParts[0];
  }
  return "";
}
