import { IAreq } from "@src/interfaces/acs.interface";
import { decode } from "@src/core/decode";
import { Utils } from "@cloudpayments/vue-utils";
import { isValidHttpUrl } from "@src/helpers/Core";

export function getAcsData(queryParams: string) {
  const urlSearchParams = new URLSearchParams(queryParams);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (!params?.AReq) {
    throw new Error("No AReq is present in request");
  }

  const areq: IAreq = JSON.parse(decode(params?.AReq));

  return {
    areq: params?.AReq,
    authorizationDataId: areq?.AuthorizationDataId,
    phone: areq?.CustomData?.Phone || "",
    amount: areq?.CustomData?.Amount || "",
    currency: areq?.CustomData?.Currency || "",
    description: areq?.CustomData?.Description || "",
    logo: areq?.CustomData?.MerchantUrl || "",
    currencySign: Utils.getCurrencySymbol(areq.CustomData.Currency),
  };
}

export function getTestAcsData(queryParams: string) {
  const urlSearchParams = new URLSearchParams(queryParams);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (!isValidHttpUrl(params.TermUrl)) {
    throw new Error("No TermUrl found");
  }

  if (params?.PaReq) {
    const decoded = JSON.parse(
      atob(params.PaReq.slice(2, params.PaReq.length))
    );
    decoded.CurrencySign = Utils.getCurrencySymbol(decoded.CurrencyCode);

    return {
      PaReq: {
        value: params.PaReq,
        decoded: decoded,
      },
      MD: params.MD,
      TermUrl: params.TermUrl,
    };
  }

  throw new Error("No Pareq found");
}
