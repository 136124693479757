import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b670709"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "phone-verification" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "sms" }
const _hoisted_5 = { class: "repeat-action" }
const _hoisted_6 = {
  key: 0,
  class: "repeat-text"
}
const _hoisted_7 = { class: "help" }
const _hoisted_8 = { class: "help__list" }
const _hoisted_9 = { class: "help__item" }
const _hoisted_10 = { class: "help__item" }
const _hoisted_11 = { class: "cancel" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_Text = _resolveComponent("Text")
  const _component_TextField = _resolveComponent("TextField")
  const _component_Button = _resolveComponent("Button")
  const _component_Tooltip = _resolveComponent("Tooltip")
  const _directive_touch = _resolveDirective("touch")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        title: _ctx.acsData.description,
        brand: _ctx.acsData.logo,
        cost: `${_ctx.acsData.amount} ${_ctx.acsData.currencySign}`
      }, null, 8, ["title", "brand", "cost"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Text, { type: "p3" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('phoneVerification.title', { phone: _ctx.acsData.phone })), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_TextField, {
        ref: "smsCodeField",
        type: "text",
        inputmode: "numeric",
        mask: _ctx.codeMask,
        label: _ctx.$t('phoneVerification.codeFromSmsLabel'),
        validation: _ctx.validators.code,
        invalid: !_ctx.validCode,
        disabled: _ctx.disabledInputCode,
        "error-label": _ctx.$t(_ctx.errorText),
        onOnUpdate: _ctx.onUpdateCode
      }, null, 8, ["mask", "label", "validation", "invalid", "disabled", "error-label", "onOnUpdate"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Button, {
        type: "primary",
        disabled: _ctx.disabledRepeatSendCode,
        onClick: _ctx.repeatSendCode,
        loading: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('phoneVerification.repeatCodeButton')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick", "loading"])
    ]),
    (_ctx.disabledRepeatSendCode && _ctx.disabledRepeatSeconds > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Text, { type: "p3" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('phoneVerification.repeatCodeText', { seconds: _ctx.disabledRepeatSeconds })), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.showTooltip)
        ? (_openBlock(), _createBlock(_component_Tooltip, {
            key: 0,
            position: "bottom",
            title: _ctx.$t('phoneVerification.help.title'),
            onOnClose: _cache[0] || (_cache[0] = $event => (_ctx.onTapTooltip(false)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_8, [
                _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.$t('phoneVerification.help.item1')), 1),
                _createElementVNode("li", _hoisted_10, _toDisplayString(_ctx.$t('phoneVerification.help.item2')), 1)
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        type: "secondary",
        disabled: _ctx.isLoading,
        onMouseover: _ctx.onTapTooltip,
        onMouseleave: _cache[1] || (_cache[1] = $event => (_ctx.onTapTooltip(false)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('phoneVerification.smsNotFound')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onMouseover"])), [
        [_directive_touch, _ctx.onTapTooltip, "tap"]
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_Button, {
        type: "tertiary",
        disabled: _ctx.isLoading,
        onClick: _ctx.showCloseModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('phoneVerification.cancel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}