<template src="./PhoneVerification.html"></template>
<script lang="ts">
import {
  defineComponent,
  inject,
  nextTick,
  onMounted,
  reactive,
  ref,
} from "vue";
import {
  Button,
  TextField,
  Text,
  Tooltip,
  Confirm,
  LoaderIcon,
  ModalService,
  Header,
} from "@cloudpayments/vue-ui-kit";

import { Fingerprint } from "@src/core/fingerprint";
import { minLength, required } from "@vuelidate/validators";
import { PhoneVerificationService } from "@src/services/PhoneVerificationService";
import { AcsService, OtpValidationStatus } from "@src/api";
import { getAcsData } from "@src/services/AcsDataUtils";
import { ACSServiceKey } from "@src/injections";

const CODE_LENGTH = 6;
const DEFAULT_SMS_REPEAT_SECONDS = 45;

export default defineComponent({
  name: "PhoneVerification",
  components: {
    Header,
    Button,
    TextField,
    Tooltip,
    Text,
    LoaderIcon,
  },
  setup() {
    const showTooltip = ref(false);
    const codeMask = "######";
    const isLoading = ref(false);
    const disabledRepeatSendCode = ref(true);
    const disabledRepeatSeconds = ref(DEFAULT_SMS_REPEAT_SECONDS);
    const disabledInputCode = ref(false);
    const validCode = ref(true);
    const code = ref("");
    const smsCodeField = ref<InstanceType<typeof TextField>>();
    const errorText = ref("phoneVerification.invalidCode");
    const acsData = reactive(getAcsData(window.location.search));

    const modal = inject<ModalService>("ModalService");

    const ACSService = inject<AcsService>(ACSServiceKey)!;

    const phoneVerificationService: PhoneVerificationService =
      new PhoneVerificationService(ACSService);

    const validators = {
      code: { required, min: minLength(CODE_LENGTH) },
    };

    onMounted(async () => {
      await sendSms();
      enableSendCode();
    });

    async function showCloseModal() {
      const modalResult = await modal?.openModal<
        typeof Confirm,
        boolean,
        {
          title: string;
          description: string;
          confirmText: string;
        }
      >({
        component: Confirm,
        model: {
          title: "modals.payment.cancelTitle",
          description: "modals.payment.cancelDescription",
          confirmText: "modals.payment.cancelSuccess",
        },
      });
      if (modalResult) {
        disabledInputCode.value = true;
        disabledRepeatSendCode.value = true;
        await close();
      }
    }

    async function close() {
      const authorizationDataId = acsData.authorizationDataId;
      const closeResponse = await phoneVerificationService.cancel(
        authorizationDataId
      );
      if (closeResponse.success) {
        await phoneVerificationService.submitToTermUrl(closeResponse);
      }
    }

    function enableSendCode() {
      let intervalSendCode = setInterval(() => {
        disabledRepeatSeconds.value = disabledRepeatSeconds.value - 1;
        if (disabledRepeatSeconds.value === 0) {
          disabledRepeatSendCode.value = false;
          clearInterval(intervalSendCode);
        }
      }, 1000);
    }

    async function repeatSendCode() {
      isLoading.value = true;

      await sendSms();

      disabledRepeatSeconds.value = 45;
      disabledRepeatSendCode.value = true;
      enableSendCode();
    }

    async function sendSms() {
      let deviceFingerprint = await Fingerprint.getFingerprint();
      const aReq = acsData.areq;

      const smsResponse = await phoneVerificationService.sendSms(
        aReq,
        deviceFingerprint
      );
      isLoading.value = false;
      if (smsResponse.success && smsResponse.resendSmsDelaySecond) {
        disabledRepeatSeconds.value = smsResponse.resendSmsDelaySecond;
        disabledInputCode.value = false;
        validCode.value = true;
        smsCodeField.value?.reset();
      } else {
        await close();
      }
    }

    async function checkCode() {
      const authorizationDataId = acsData.authorizationDataId;

      const checkOtpResponse = await phoneVerificationService.checkCode(
        authorizationDataId,
        code.value
      );
      if (
        checkOtpResponse &&
        checkOtpResponse.success &&
        checkOtpResponse.status === OtpValidationStatus.Success
      ) {
        try {
          await phoneVerificationService.submitToTermUrl(checkOtpResponse);
        } catch (e) {
          await close();
        }
      } else if (
        checkOtpResponse?.status === OtpValidationStatus.TryAgain ||
        checkOtpResponse?.status === OtpValidationStatus.OutOfAttempts
      ) {
        validCode.value = false;
        disabledInputCode.value =
          checkOtpResponse.status === OtpValidationStatus.OutOfAttempts;

        errorText.value =
          checkOtpResponse?.status === OtpValidationStatus.TryAgain
            ? "phoneVerification.invalidCode"
            : "phoneVerification.outOfAttempt";

        if (disabledRepeatSeconds.value === 0) {
          disabledRepeatSendCode.value = false;
        }

        nextTick(() => {
          smsCodeField.value?.setTouched();
          smsCodeField.value?.blur();
        });
      } else {
        await close();
      }
    }

    function onUpdateCode(updateCode: string) {
      if (updateCode === code.value) {
        return;
      }
      code.value = updateCode;

      if (smsCodeField.value?.isValid() && code.value.length === CODE_LENGTH) {
        validCode.value = true;
        disabledInputCode.value = true;
        disabledRepeatSendCode.value = true;
        checkCode();
      }
    }

    function onTapTooltip(showTooltipValue = true) {
      showTooltip.value = showTooltipValue;
    }

    return {
      acsData,
      smsCodeField,
      validators,
      validCode,
      disabledInputCode,
      isLoading,
      disabledRepeatSendCode,
      disabledRepeatSeconds,
      codeMask,
      showTooltip,
      errorText,
      repeatSendCode,
      showCloseModal,
      onUpdateCode,
      onTapTooltip,
    };
  },
});
</script>
<style src="./PhoneVerification.scss" lang="scss" scoped></style>
