import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
const PhoneVerification = () =>
  import("@src/components/PhoneVerification/PhoneVerification.vue");
const Test = () => import("@src/components/Test/Test.component.vue");
const SbpDemo = () =>
  import("@src/components/PaymentDemo/sbp/SbpDemo.component.vue");
const TinkoffPayDemo = () =>
  import("@src/components/PaymentDemo/tpay/TinkoffPayDemo.component.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: PhoneVerification,
  },
  {
    path: "/test-get",
    name: "Test",
    component: Test,
  },
  {
    path: "/sbp/test/:id",
    name: "SBP",
    component: SbpDemo,
  },
  {
    path: "/tinkoffpay/test/:id",
    name: "TinkoffPay",
    component: TinkoffPayDemo,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
