<div class="phone-verification">
    <div class="header">
        <Header
        :title="acsData.description"
        :brand="acsData.logo"
        :cost="`${acsData.amount} ${acsData.currencySign}`"></Header>
    </div>
    <div class="content">
        <Text type="p3">{{ $t('phoneVerification.title', { phone: acsData.phone }) }}</Text>
    </div>
    <div class="sms">
        <TextField
                ref="smsCodeField"
                type="text" inputmode="numeric" :mask="codeMask"
                :label="$t('phoneVerification.codeFromSmsLabel')"
                :validation="validators.code"
                :invalid="!validCode"
                :disabled="disabledInputCode"
                :error-label="$t(errorText)"
                @onUpdate="onUpdateCode"/>
    </div>
    <div class="repeat-action">
        <Button type="primary" :disabled="disabledRepeatSendCode" v-on:click="repeatSendCode" :loading="isLoading">
            {{ $t('phoneVerification.repeatCodeButton') }}
        </Button>
    </div>
    <div class="repeat-text" v-if="disabledRepeatSendCode && disabledRepeatSeconds > 0">
        <Text type="p3">
            {{ $t('phoneVerification.repeatCodeText', { seconds: disabledRepeatSeconds }) }}
        </Text>
    </div>
    <div class="help">
        <Tooltip v-if="showTooltip" position="bottom" :title="$t('phoneVerification.help.title')" @onClose="onTapTooltip(false)">
            <ul class="help__list">
                <li class="help__item">
                    {{ $t('phoneVerification.help.item1') }}
                </li>
                <li class="help__item">
                    {{ $t('phoneVerification.help.item2') }}
                </li>
            </ul>
        </Tooltip>
        <Button type="secondary"
                :disabled="isLoading"
                v-touch:tap="onTapTooltip"
                v-on:mouseover="onTapTooltip"
                v-on:mouseleave="onTapTooltip(false)">
            {{ $t('phoneVerification.smsNotFound') }}
        </Button>
    </div>
    <div class="cancel">
        <Button type="tertiary"
                :disabled="isLoading"
                v-on:click="showCloseModal">
            {{ $t('phoneVerification.cancel') }}
        </Button>
    </div>
</div>
