import { createApp } from "vue";
import App from "./App/App.vue";
import Maska from "maska";
import { langPlugin } from "@src/langSetup";
import { applyDirectives, SkeletonDirective } from "@cloudpayments/vue-ui-kit";
import Vue3TouchEvents from "vue3-touch-events";
import router from "./router";

import "./scss/main.scss";
import "@cloudpayments/vue-ui-kit/dist/style.css";
import {
  ACSServiceKey,
  DomainConfigurationKey,
  FeatureToggleServiceKey,
  ProxyPayServiceKey,
} from "@src/injections";
import { FeatureApiService } from "@src/services/FeatureApiService";
import { AppClient } from "@src/api";

const app = createApp(App);

const getConfiguration = async () => {
  console.log(process.env);
  if (process.env.NODE_ENV === "development") {
    return (await import("./runtime-configuration/configuration.local"))
      .configurationLocal;
  } else {
    const featureApiService = new FeatureApiService();

    const configData = await featureApiService.loadConfiguration();

    if (!configData) {
      throw new Error("Cannot get configuration.");
    }

    return configData;
  }
};

getConfiguration().then((domainConfig) => {
  app.provide(DomainConfigurationKey, domainConfig);
  app.provide(FeatureToggleServiceKey, new FeatureApiService());

  const appClient = new AppClient({ BASE: domainConfig.api.host });

  app.provide(ACSServiceKey, appClient.acs);
  app.provide(ProxyPayServiceKey, appClient.proxyPay);

  app.use(Vue3TouchEvents);
  applyDirectives(app, [SkeletonDirective]);

  app.use(Maska);
  app.use(router);
  app.use(langPlugin, domainConfig);
  app.mount("#app");
});
