import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6539b2f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "test" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "repeat-action" }
const _hoisted_5 = { class: "help" }
const _hoisted_6 = { class: "cancel" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_Text = _resolveComponent("Text")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        title: _ctx.acsData.PaReq.decoded.Description,
        brand: _ctx.acsData.PaReq.decoded.MerchantLogoUrl,
        cost: `${_ctx.acsData.PaReq.decoded.Amount} ${_ctx.acsData.PaReq.decoded.CurrencySign}`
      }, null, 8, ["title", "brand", "cost"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Text, { type: "p3" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('test.title')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Button, {
        type: "primary",
        onClick: _ctx.onSuccess
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('test.success')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Button, {
        type: "secondary",
        onClick: _ctx.onFail
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('test.fail')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        type: "tertiary",
        onClick: _ctx.onFail
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('phoneVerification.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}