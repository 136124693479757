/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OtpValidationStatus {
    Success = 0,
    Expire = 1,
    Failed = 2,
    OutOfAttempts = 3,
    TryAgain = 4,
}