import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "acs"
}
const _hoisted_2 = { class: "acs__wrapper" }
const _hoisted_3 = { class: "acs__content" }

export function render(_ctx, _cache) {
  const _component_Modal = _resolveComponent("Modal")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_router_view)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}