import { AcsService, CheckOtpResponse, SmsResponse } from "@src/api";
import { isValidHttpUrl } from "@src/helpers/Core";

export class PhoneVerificationService {
  constructor(private readonly acsService: AcsService) {}

  async cancel(authorizationDataId?: string): Promise<SmsResponse> {
    if (!authorizationDataId) {
      throw new Error("error");
    }

    try {
      const smsResponse = await this.acsService.postCancel({
        authorizationDataId,
      });
      if (smsResponse?.success) {
        return smsResponse;
      } else {
        return this.getCloseResult();
      }
    } catch (e: any) {
      return this.getCloseResult();
    }
  }

  async sendSms(
    aReq?: string,
    deviceFingerprint?: string
  ): Promise<SmsResponse> {
    if (!deviceFingerprint || !aReq) {
      throw new Error("error");
    }

    try {
      const smsResponse = await this.acsService.postSendSms({
        aReq,
        deviceFingerprint,
      });
      if (smsResponse?.success && smsResponse.resendSmsDelaySecond) {
        return smsResponse;
      } else {
        return this.getCloseResult();
      }
    } catch (e: any) {
      return this.getCloseResult();
    }
  }

  async checkCode(
    authorizationDataId?: string,
    code?: string | null
  ): Promise<CheckOtpResponse> {
    try {
      const checkOtpResponse = await this.acsService.postCheckOtp({
        authorizationDataId,
        code,
      });
      if (checkOtpResponse) {
        return checkOtpResponse;
      } else {
        return this.getCloseResult();
      }
    } catch (e: any) {
      return this.getCloseResult();
    }
  }

  async submitToTermUrl(checkOtpResponse: AppCloseResponse): Promise<any> {
    if (!checkOtpResponse) {
      return this.getCloseResult();
    }
    const { termUrl, aRes, md } = checkOtpResponse;

    if (!isValidHttpUrl(String(termUrl))) {
      throw new Error("No TermUrl found");
    }

    if (!termUrl || !aRes || !md) {
      return this.getCloseResult();
    }

    const form = document.createElement("form");
    form.hidden = true;
    form.action = termUrl;
    form.method = "POST";

    const paResInput = document.createElement("input");
    paResInput.value = aRes;
    paResInput.name = "PaRes";

    const mdInput = document.createElement("input");
    mdInput.value = md;
    mdInput.name = "MD";

    form.appendChild(paResInput);
    form.appendChild(mdInput);

    const hiddenFormWrapper = document?.getElementById("form");

    if (hiddenFormWrapper) {
      hiddenFormWrapper.append(form);
      form.submit();
      return this.getCloseResult(true);
    } else {
      return this.getCloseResult(false);
    }
  }

  async getCloseResult(success = false): Promise<{ success: boolean }> {
    return new Promise((resolve) => {
      resolve({
        success,
      });
    });
  }
}

export type AppCloseResponse = {
  aRes?: string | null;
  md?: string | null;
  termUrl?: string | null;
  success?: boolean;
  errorMessage?: string | null;
};
