<template src="./App.html"></template>
<style src="./App.scss" lang="scss"></style>
<script lang="ts">
import { defineComponent, onMounted, provide, reactive, ref } from "vue";
import {
  Modal,
  Header,
  LoaderService,
  ModalService,
} from "@cloudpayments/vue-ui-kit";
import { loadLocaleMessages } from "@src/langSetup";

import PhoneVerification from "@src/components/PhoneVerification/PhoneVerification.vue";
import Test from "@src/components/Test/Test.component.vue";

const App = defineComponent({
  components: {
    Modal,
    PhoneVerification,
    Test,
    Header,
  },
  setup() {
    const showContent = ref(false);
    const currencySymbol = ref();

    const loader = reactive(new LoaderService());
    const modal = reactive(new ModalService());

    onMounted(async () => {
      await loadLocaleMessages();
      showContent.value = true;
    });

    provide("LoaderService", loader);
    provide("ModalService", modal);

    return {
      showContent,
      loader,
      currencySymbol,
    };
  },
});

export default App;
</script>
