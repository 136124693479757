import { InjectionKey } from "vue";
import { FeatureApiService } from "@src/services/FeatureApiService";
import { DomainConfiguration } from "@src/runtime-configuration/DomainConfiguration";
import { AcsService, ProxyPayService } from "@src/api";

export const DomainConfigurationKey: InjectionKey<DomainConfiguration> = Symbol(
  "DomainConfiguration"
);

export const FeatureToggleServiceKey: InjectionKey<FeatureApiService> = Symbol(
  "FeatureToggleService"
);
export const ACSServiceKey: InjectionKey<AcsService> = Symbol("ACSServiceKey");
export const ProxyPayServiceKey: InjectionKey<ProxyPayService> =
  Symbol("ProxyPayServiceKey");
