/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AReqResponse } from '../models/AReqResponse';
import type { CancelCommand } from '../models/CancelCommand';
import type { CancelResponse } from '../models/CancelResponse';
import type { CheckAResCommand } from '../models/CheckAResCommand';
import type { CheckAResResponse } from '../models/CheckAResResponse';
import type { CheckOtpCommand } from '../models/CheckOtpCommand';
import type { CheckOtpResponse } from '../models/CheckOtpResponse';
import type { CreateAReqCommand } from '../models/CreateAReqCommand';
import type { SendSmsCommand } from '../models/SendSmsCommand';
import type { SmsResponse } from '../models/SmsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AcsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns AReqResponse Success
     * @throws ApiError
     */
    public postGetAreq(
requestBody?: CreateAReqCommand,
): CancelablePromise<AReqResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/get_areq',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public postCheckAreq(
formData?: {
PaReq?: string;
TermUrl?: string;
Md?: string;
},
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/check_areq',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody 
     * @returns SmsResponse Success
     * @throws ApiError
     */
    public postSendSms(
requestBody?: SendSmsCommand,
): CancelablePromise<SmsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/send_sms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CheckOtpResponse Success
     * @throws ApiError
     */
    public postCheckOtp(
requestBody?: CheckOtpCommand,
): CancelablePromise<CheckOtpResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/check_otp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CheckAResResponse Success
     * @throws ApiError
     */
    public postCheckAres(
requestBody?: CheckAResCommand,
): CancelablePromise<CheckAResResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/check_ares',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CancelResponse Success
     * @throws ApiError
     */
    public postCancel(
requestBody?: CancelCommand,
): CancelablePromise<CancelResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/cancel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}