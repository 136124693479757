<div class="test">
    <div class="header">
        <Header
        :title="acsData.PaReq.decoded.Description"
        :brand="acsData.PaReq.decoded.MerchantLogoUrl"
        :cost="`${acsData.PaReq.decoded.Amount} ${acsData.PaReq.decoded.CurrencySign}`"></Header>
    </div>
    <div class="content">
        <Text type="p3">
            {{ $t('test.title') }}
        </Text>
    </div>
    <div class="repeat-action">
        <Button type="primary" v-on:click="onSuccess">
            {{ $t('test.success') }}
        </Button>
    </div>
    <div class="help">
        <Button type="secondary" v-on:click="onFail">
            {{ $t('test.fail') }}
        </Button>
    </div>
    <div class="cancel">
        <Button type="tertiary" v-on:click="onFail">
            {{ $t('phoneVerification.cancel') }}
        </Button>
    </div>
</div>
