<template src="./Test.component.html"></template>

<script lang="ts">
import { defineComponent, inject, reactive } from "vue";
import { Button, TextField, Text, Header } from "@cloudpayments/vue-ui-kit";
import { PhoneVerificationService } from "@src/services/PhoneVerificationService";
import { getTestAcsData } from "@src/services/AcsDataUtils";
import { AcsService } from "@src/api";
import { ACSServiceKey } from "@src/injections";

export default defineComponent({
  name: "Test",
  components: {
    Header,
    Button,
    Text,
    TextField,
  },
  setup() {
    const acsData = reactive(getTestAcsData(window.location.search));
    console.log(acsData);

    const ACSService = inject<AcsService>(ACSServiceKey)!;

    const phoneVerificationService: PhoneVerificationService =
      new PhoneVerificationService(ACSService);

    const onSuccess = async function () {
      await phoneVerificationService.submitToTermUrl({
        aRes: "AQ==",
        md: acsData?.MD,
        termUrl: acsData.TermUrl,
      });
    };

    const onFail = async function () {
      await phoneVerificationService.submitToTermUrl({
        aRes: "AA==",
        md: acsData.MD,
        termUrl: acsData.TermUrl,
      });
    };

    return {
      acsData,
      onSuccess,
      onFail,
    };
  },
});
</script>

<style src="./Test.component.scss" lang="scss" scoped></style>
