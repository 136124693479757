/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SbpPaymentChangeStateResponse } from '../models/SbpPaymentChangeStateResponse';
import type { SbpPaymentFinalStateCommand } from '../models/SbpPaymentFinalStateCommand';
import type { TinkoffPayPaymentChangeStateResponse } from '../models/TinkoffPayPaymentChangeStateResponse';
import type { TinkoffPayPaymentFinalStateCommand } from '../models/TinkoffPayPaymentFinalStateCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProxyPayService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns SbpPaymentChangeStateResponse Success
     * @throws ApiError
     */
    public postSbpPaymentFinalState(
requestBody?: SbpPaymentFinalStateCommand,
): CancelablePromise<SbpPaymentChangeStateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sbp/payment_final_state',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TinkoffPayPaymentChangeStateResponse Success
     * @throws ApiError
     */
    public postTinkoffpayPaymentFinalState(
requestBody?: TinkoffPayPaymentFinalStateCommand,
): CancelablePromise<TinkoffPayPaymentChangeStateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tinkoffpay/payment_final_state',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}