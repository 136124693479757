import { Lang } from "@cloudpayments/vue-utils";

export function isValidHttpUrl(param: string): boolean {
  let url;
  try {
    url = new URL(param);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
